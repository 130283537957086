import React, { useState } from 'react';
import t from 'tcomb';
import tcombValidate from 'tcomb-validation';
import { Button, Card, Form } from 'react-bootstrap';
import cx from 'classnames';
import moment from 'moment';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { createPatient, updateAttributeValue } from './symptoAuth';

const DEFAULT_JSON = `{
  "member_id": "A12684665",
  "member_fst_name": "John",
  "member_md_name": "A.",
  "member_lst_name": "Doe",
  "birth_date": "1990-01-01",
  "gender": "M",
  "zipcode": 10001,
  "country": "US",
  "state": "NY",
  "plan": "PPO",
  "contact_number": 1234567890,
  "email_id": null,
  "is_onboarded": true
}`;

const DateFormat = t.refinement(t.String, s => moment(s, 'YYYY-MM-DD', true).isValid());
const NewPatient = t.struct({
  member_id: t.String, // mrn
  member_fst_name: t.String,
  member_md_name: t.maybe(t.String),
  member_lst_name: t.maybe(t.String),
  birth_date: DateFormat,
  gender: t.maybe(t.String),
  zipcode: t.maybe(t.Number),
  country: t.maybe(t.String),
  state: t.maybe(t.String),
  plan: t.maybe(t.String),
  contact_number: t.maybe(t.Number),
  email_id: t.maybe(t.String),
  is_onboarded: t.Boolean,
}, 'NewPatient');

const UpdatePatient = t.struct({
  symptoId: t.String,
  condition_type: t.enums.of(['CRS', 'DRS', 'HRS']),
  risk_score: t.Number,
});


type OpType = 'New Patient' | 'Update Patient';
const getErrors = (data: string, type: OpType): React.ReactNode => {
  try {
    const parsed = JSON.parse(data);
    const { errors } = tcombValidate.validate(parsed, type === 'New Patient' ? NewPatient : UpdatePatient);

    if (errors.length > 0) {
      return (errors.map((e) => (
        <div>
          {e.message}
        </div>
      )));;
    } else {
      return null;
    }
  } catch (e: any) {
    return (
      <div>
        {e.message}
      </div>
    );
  }
}

const App = () => {
  const [code, setCode] = React.useState(DEFAULT_JSON);
  const [auth, setAuth] = React.useState({
    username: '',
    password: '',
  });
  const [error, setError] = React.useState<React.ReactNode | null>(null);
  const [previousRuns, setPreviousRuns] = useState<{
    timestamp: number, data: string, successMetrics: string,
  }[]>([]);
  const [changeType, setChangeType] = useState<OpType>('New Patient');
  return (
    <div className="w-100 bg-light p-4" style={{ height: '100vh' }}>
      <Card className="mx-auto d-block border bg-white w-50 p-4 mb-3">
        <Form.Label className="text-secondary font-weight-light">
          Email
        </Form.Label>
        <Form.Control
          type="text"
          value={auth.username}
          onChange={(e) => {
            setAuth({
              ...auth,
              username: e.target.value,
            });
          }}
        />
        <Form.Label className="text-secondary font-weight-light">
          Password
        </Form.Label>
        <Form.Control
          type="password"
          value={auth.password}
          onChange={(e) => {
            setAuth({
              ...auth,
              password: e.target.value,
            });
          }}
        />
      </Card>
      <Card className="w-50 mx-auto d-block p-3">
        {
          (['New Patient', 'Update Patient'] as OpType[]).map((type) => (
            <Button
              key={type}
              style={{
                textDecoration: 'none',
                fontSize: '0.8em',
              }}
              variant={changeType === type ? 'primary' : 'link'}
              className={cx('mr-3 mb-3', { 'text-secondary': changeType !== type })}
              onClick={() => {
                setChangeType(type);
                setError(getErrors(code, type));
              }}
            >
              {type}
            </Button>
          ))
        }
        <CodeEditor
          value={code}
          language="js"
          placeholder="Please enter JS code."
          onChange={(evn) => {
            setCode(evn.target.value);
            setError(getErrors(evn.target.value, changeType));
          }}
          padding={15}
          className="rounded border"
          style={{
            fontSize: 12,
            backgroundColor: "#f5f5f5",
            fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
          }}
        />
        <Button
          style={{ fontSize: '0.85em', fontWeight: 600, color: '#2980b9' }}
          onClick={async () => {
            const errors = getErrors(code, changeType);
            if (errors != null) {
              setError(errors);
            } else {
              const patientData = changeType === 'New Patient'
                ? await createPatient(JSON.parse(code), auth)
                : await updateAttributeValue(JSON.parse(code), auth);
              if (patientData != null ){
                setPreviousRuns((prev) => ([
                  ...prev,
                  {
                    timestamp: Date.now(),
                    data: code,
                    successMetrics: patientData,
                  }
                ]));
                setCode(DEFAULT_JSON);
                setError(null);
              }
            }
          }}
          disabled={error != null}
          className="mt-3 d-block mx-auto  text-decoration-none"
          variant="link"
        >
          Execute JSON
        </Button>
        {
          error && (
            <div className="text-danger font-weight-bold mt-4 border-top pt-3 mb-3" style={{ fontSize: '0.8em' }}>
              {error}
            </div>
          )
        }
      </Card>
      {previousRuns.length > 0 && (
        <div
          className="d-block mx-auto text-center mt-4"
          style={{ fontSize: '1.2em', fontWeight: '300' }}
        >
          Previous JSON Runs
        </div>
      )}
      {previousRuns.map(({ timestamp, data, successMetrics }) => (
        <Card key={timestamp} className="card mx-auto w-50 mt-3 p-3">
          <CodeEditor
            value={data}
            language="json"
            padding={15}
            className="rounded border"
            onChange={(evn) => {
              evn.preventDefault();
              evn.stopPropagation();
            }}
            onKeyDown={(en) => {
              en.preventDefault();
              en.stopPropagation();
            }}
            style={{
              fontSize: 12,
              cursor: 'pointer',
              backgroundColor: "#f5f5f5",
            }}
          />
          <pre>
            {successMetrics}
          </pre>
          <div
            style={{
              fontSize: '0.8em',
            }}
            className="text-secondary mt-2"
          >
            {`Exected ${moment(timestamp).fromNow()} at ${moment(timestamp).format('h:mm:ss a')}`}
          </div>
        </Card>
      ))}
      {previousRuns.length > 0 && (
        <Button variant="link" className="mx-auto d-block" style={{ fontSize: '0.8em' }}>
          Clear Runs
        </Button>
      )}
    </div>
  );
};

export default App;
