
import swal from 'sweetalert';
const CLIENT_URL = 'https://api.pristine.symptodev.site';

export const symptoReq = async (url: string, params = {}, headers = {}) => {
	const resp = await (await fetch(`${CLIENT_URL}${url}`, {
	  "headers": {
	    "accept": "application/json, text/plain, */*",
	    "accept-language": "en-US,en;q=0.9",
			"cache-control": "no-cache",
			"content-type": "application/json;charset=UTF-8",
			"pragma": "no-cache",
			"Referer": "https://app.symptohealth.com/",
			"Referrer-Policy": "strict-origin-when-cross-origin",
			...headers
	  },
	  "referrer": "https://app.symptohealth.com/",
	  "referrerPolicy": "strict-origin-when-cross-origin",
	  ...params
	})).json();
	if (resp.Status !== 'OK') {
    swal(`Failed ${url}. ${JSON.stringify(resp)}`);
		throw new Error(`Failed ${url}. ${JSON.stringify(resp)}`)
	}
	return resp.Response;
};

export const generateAuthCode = async ({
	username,
  password,
}: {
	username: string,
	password: string,
}): Promise<{
	authCode: string,
}> => {
  console.log("\n\n\nAuthenticating user...");
  try {
    const authCode = await symptoReq(`/authorization`, {
      body: JSON.stringify({
				clientId: username,
      	clientSecret: password,
			}),
			method: 'POST',
    });
    console.log("User authenticated");
    return { authCode };
  } catch (e: any) {
    swal('Errored on generating bearer token', e.response.data);
    throw new Error(JSON.stringify(e.response.data));
  }
}
