export const PATIENT_ATTRIBUTE_MAPPING = {
  visibilityOnPatientCreation: 'hidden',
  title: 'Notification Channels',
  id: '02dd2abb-765f-469a-8b29-2ca9cfd407ca',
  isVisibleOnDashboard: false,
  name: 'notificationChannels',
  isExportable: false,
  reserved: true,
  type: 'string',
  defaultValue: null,
  formatType: 'regular',
  under_18: '030f91a9-bfc9-409a-834d-9ad160fc271e',
  newest_patient_message_time: '03a28180-72db-4666-a633-9a3937a83fc5',
  email: '0752401c-e1f5-4e77-a191-6cd8c994791f',
  planName: '0d960143-d1a9-4611-983c-cc828ff859ba',
  dup_phone_number: '1b94d6c7-57a6-4508-961d-d31c8aacf3ae',
  depressionCondition: '253c14c2-3989-44ba-b9c5-fa0e3e63518f',
  CRS: '268bea16-2458-445a-96ee-5f35cd8494dc', // number
  mrn: '282e01c0-90db-4dfa-ac55-fd5345be6535',
  segment: '31faaf50-321e-4a39-8de2-bab73ea2b5c9',
  DRS: '32c8b760-13bc-4951-b6aa-e54175368f35', // number
  phoneNumber: '371bf179-9b3a-42bf-b9b6-25fa139b51dd',
  county: '37d65a6a-b6a1-41e2-b270-f7a0155d79bf',
  patient_created_time: '3d75b4d9-11c3-4256-818c-c1c6841796a4',
  region: '481638e9-ed70-4f69-9e9a-a930cc181295',
  language: '5c111b47-5359-41d1-a0ec-702255ffe9f3',
  dob: '64d6025b-6904-40be-a10a-5f687d3eb02e',
  'memberInfo.onboarded': '70082aa8-4d11-4b7a-a07f-eb37c2b45b74',
  state: '77e6c5a2-28a0-4b94-955c-7e9a1547874e',
  individualGenderCd: '8431941a-e78a-446f-89fa-476fce288037',
  HRS: '858297e9-d487-43ce-89c8-37b6e5853c12', // number
  firstName: '8891617d-034a-47db-b7c9-60491eb85fb5',
  networkType: 'bc829a81-5dc3-44b8-b8be-ed9dd5813150',
  cancerCondition: 'd451166c-0e93-4080-963c-c6d1036db046',
  zipCode: 'db95847a-1ce5-4f00-ae67-9c8f1dd34a12',
  hypertensionCondition: 'dc8a1cd9-2c3b-43df-978c-4c751b7376d7',
  lastName: 'de5bbf41-b20e-4778-a785-0d8c547a2831',
  contractStatus: 'e421a5da-37a4-4fee-b6ab-c7bc7263323f',
  contractNumber: 'eaefc6b6-a8fb-47ab-86c0-bf5654798faf',
};


