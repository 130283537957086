import _ from 'lodash';
import moment from 'moment';
import swal from 'sweetalert';
import { symptoReq,  generateAuthCode } from './symptoUtils';
import { NewPatient } from './types';
import { PATIENT_ATTRIBUTE_MAPPING } from './attributes';

export const createPatient = async (
  patientData: NewPatient,
  authData: {
    username: string, password: string,
  },
): Promise<null | string> => {
  const { authCode } = await generateAuthCode(authData)
	const patientPayload = {
		firstName: patientData['member_fst_name'],
		lastName: `${patientData['member_md_name'] || ''} ${patientData['member_lst_name'] || ''}`,
		sex: patientData['gender'],
		dob: moment(patientData['birth_date'], 'YYYY-MM-DD').format('MM/DD/YYYY'),
		timeZone: 'America/Los_Angeles',
		profilePicture: null,
		language: null,
		notes: null,
		mrn: patientData['member_id'],
    notificationType: _.compact([
      patientData['email_id'] != null ? 'email' : null,
      patientData['contact_number'] != null ? 'text' : null,
    ]),
    email: patientData['email_id'],
    phoneNumber: String(patientData['contact_number']),
		campaignIds: null,
		groupId: null,
	};

  const patientAttrs = [{
    patientAttributeId: PATIENT_ATTRIBUTE_MAPPING.zipCode,
    value: patientData.zipcode,
  }, {
    patientAttributeId: PATIENT_ATTRIBUTE_MAPPING.county,
    value: patientData.country,
  }, {
    patientAttributeId: PATIENT_ATTRIBUTE_MAPPING.state,
    value: patientData.state,
  }, {
    patientAttributeId: PATIENT_ATTRIBUTE_MAPPING.planName,
    value: patientData.plan,
  }, {
    patientAttributeId: PATIENT_ATTRIBUTE_MAPPING['memberInfo.onboarded'],
    value: patientData.is_onboarded,
  }]
  const patient = await symptoReq(`/providers/patients`, {
		"body": JSON.stringify({
			...patientPayload,
			patientAttributes: patientAttrs,
		}),
		"method": "POST",
	},  {'Authorization': 'Bearer '+authCode });
	if (patient.type === 'success') {
		return JSON.stringify({
      patientTvId: patient.patientTvId,
    }, null, 4);
	}
  swal('Error', 'Unable to create patient', 'error');
  return null;
}

export const updateAttributeValue = async (
  patientData: {
    symptoId: string,
    condition_type: string,
    risk_score: number,
  },
  authData: {
    username: string, password: string,
  },
): Promise<null | string> => {
  const { authCode } = await generateAuthCode(authData)
  await symptoReq(`/providers/${patientData.symptoId}/attributes`, {
		"body": JSON.stringify({
      name: patientData.condition_type,
      attributeValue: {
        type: 'number',
        formatType: 'regular',
        value: patientData.risk_score,
      },
		}),
		"method": "POST",
	},  {'Authorization': 'Bearer '+authCode });
	return 'Success';
}
